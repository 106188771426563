<template>

    <table  style ="width: 100%; border: solid white 1px;">
        <thead>
            <tr style="border: solid white 1px;">
                <!-- loop through each value of the fields to get the table header -->
                <!-- <th v-for="field in fields" :key='field' @click="sortTable(field)">
                    {{ field }} <i class="bi bi-sort-alpha-down" aria-label='Sort Icon'></i>
                </th> -->
            </tr>
        </thead>
        <tbody>
            <!-- Loop through the list get the each student data -->
            <tr class="tb" style="border: solid white 1px; " v-for="item in ZabiegiPielegnacyjneDane" :key='item'>
                <td>{{ item['Nazwa'] }} <div style="float:right;">{{  item['Cena'] }} zł</div>  </td>
            </tr>
        </tbody>
        
    </table>
</template>
<style>

    td {
        padding: 10px;
        text-align: center;
    }
</style>
<script>
export default {
    name: 'ZabiegiPielegnacyjneTable',
    setup() {
    //An array of values for the data
    
    const ZabiegiPielegnacyjneDane = [
      { Nazwa: "Oczyszczanie twarzy", Cena:  '150'},
      {  Nazwa: "Oczyszczanie pleców", Cena: '180' },
      {  Nazwa: "Masaż twarzy", Cena: '50' },
     {Nazwa: 'Maski ', Cena: 'od 45'},
      {  Nazwa: " Eksfoliacja kwasami (twarz)", Cena: '80' },
      {  Nazwa: " Eksfoliacja (twarz, szyja)", Cena: '120' },
      {  Nazwa: "Eksfoliacja (twarz, szyja,dekolt)", Cena: '140' },
      {Nazwa: 'Zabieg z kwasami peel mission  ', Cena:'od 80'},
      {  Nazwa: "Zabieg z kwasem glikolowym charm medi", Cena: '150' },
      {  Nazwa: "Kawitacja", Cena: '55' },
      {  Nazwa: "Sonoforeza/ lifting", Cena: '55' },
      { Nazwa: 'Fala radiowa + ampułka + maska (twarz)', Cena: '130'},
      {Nazwa: 'Zabieg nawilżająco liftingujacy twarz, szyja dekolt  ', Cena:'170'},
      {  Nazwa: "Wild berry ferment peel", Cena: '50' },
      {  Nazwa: "Wild berry ferment peel + kawitacja", Cena: '90' },
      {  Nazwa: "Drenaż limfatyczny twarzy", Cena: '50' },
      {  Nazwa: "Drenaż limfatyczny – ‘ciężkie nogi’", Cena: '65' },
      {  Nazwa: "Biologiczny drenaż ciała", Cena: '90' },
      {Nazwa: 'Mezoterapia ', Cena:'155'},
      {  Nazwa: "Mezoterapia frakcyjna (twarz)", Cena: '155' },
      {Nazwa: 'Mezoterapia twarz szyja' , Cena:'180'},
      {Nazwa: 'Mezoterapia twarz szyja dekolt ', Cena:'230'},
      {  Nazwa: "Mikrodermabrazja", Cena: '110' },
      {  Nazwa: "Oczyszczanie wodorowe (twarz)", Cena: '170' },
      {  Nazwa: "Oczyszczanie wodorowe (twarz, szyja)", Cena: '195' },
      {  Nazwa: "Oczyszczanie wodorowe (twarz, szyja, dekolt)", Cena: '235' },
      {Nazwa: 'Makijaż wieczorowy' , Cena:'120'},
      {  Nazwa: "Makijaż ślubny", Cena: '140' },
      {  Nazwa: "Przedłużanie rzęs 1:1", Cena: '150' },
      {  Nazwa: "Przedłużanie rzęs 2:1", Cena: '180' },
      {  Nazwa: "Uzupełnienie rzęs", Cena: '110' },
      {  Nazwa: "Lifting rzęs + farbka", Cena: '90' },
      {Nazwa: 'Przekłucie uszu ', Cena:'90'},
      

// {Nazwa: 'Kawitacja/ sonoforeza/ lifting', Cena: '55'},
// {Nazwa: 'Fala radiowa + ampułka + maska (twarz)', Cena: '130'},
// {Nazwa: 'Mezoterapia ', Cena:'155'},
// {Nazwa: 'Mezoterapia twarz szyja' , Cena:'180'},
// {Nazwa: 'Mezoterapia twarz szyja dekolt ', Cena:'230'},
// {Nazwa: 'Mikrodermabrazja', Cena: '110'},
// {Nazwa: 'Makijaż wieczorowy' , Cena:'120'},
// {Nazwa: 'Makijaż ślubny' , Cena:'140'},
// {Nazwa: 'Przekłucie uszu ', Cena:'90'},


    ]
    const fields = [
      'Nazwa', 'Cena'
    ]
    return { ZabiegiPielegnacyjneDane,  fields }
  },
    
}
</script>+