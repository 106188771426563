<template>
  <div>
    <article class="item">
      <div class="content clearfix">
        <h3>Salon urody Ilona</h3>
        <p>
          <img
            alt=""
            src="images/grafika/zdjecie.png"
            style="float: left; padding: 5px"
          />
        </p>
        <p>
          Istnieje od 2009r. jest to miejsce stworzone z
          myslą o osobach, ktore chcą miło spędzic czas,
          zadbać o sw&oacute;j wygląd i podarowac sobie
          chwilę odprężenia.
        </p>
        <p>
          Oferujemy szeroki wachlarz usług z zakresu:
          pielęgnacji twarzy, ciała, paznokci.
          Specjalizujemy się w makijażu ślubnym,
          wieczorowym, jak r&oacute;wnież w elementach
          charakteryzacji.
        </p>
        <p>
          Gwarantujemy indywidualne podejscie do klienta,
          miła atmosfere, fachowe porady, a także komfort i
          bezpieczeństwo.
        </p>
        <p>
          Pracujemy wyłącznie na profesjonalnych kosmetykach
          i urządzeniach skutecznych w działaniu. Posiadamy
          liczne certyfikaty i wciąż sie szkolimy.
        </p>
        <p>
          Dla naszych stałych klient&oacute;w oferujemy
          karty rabatowe.
        </p>
        <h4 style="text-align: center">
          Serdecznie zapraszamy!
        </h4>
        <p>&nbsp;</p>
        <p>
          <span class="userContent"
            >Z dumą informujemy Państwa, że w IV lubelskich
            mistrzostwach makijażu zajeliśmy II miejsce w
            tematyce &quot;Botanique rock&quot;. Makijaż
            został wykonany prze Ilone Paluszek.</span
          >
        </p>
        <p>
          <span class="userContent"
            ><div style="clear: both"></div>
            
            <div id="AG_01" class="ag_reseter AG_classic">
              <table
                cellspacing="0"
                cellpadding="0"
                border="0"
               >
                <tbody>
                  <tr>
                    <td>
                      <span class="ag_thumbclassic"
                        ><a
                          href="images/sampledata/galeria/2miejsce/2.jpg"
                          title="2.jpg"
                          class=""
                          rel="lightbox[AdmirorGallery01]"
                          target="_blank"
                          ><img
                            src="plugins/content/admirorgallery/admirorgallery/thumbs/galeria/2miejsce/2.jpg"
                            alt="2.jpg"
                            class="ag_imageThumb" /></a></span
                      ><span class="ag_thumbclassic"
                        ><a
                          href="images/sampledata/galeria/2miejsce/3.jpg"
                          title="3.jpg"
                          class=""
                          rel="lightbox[AdmirorGallery01]"
                          target="_blank"
                          ><img
                            src="plugins/content/admirorgallery/admirorgallery/thumbs/galeria/2miejsce/3.jpg"
                            alt="3.jpg"
                            class="ag_imageThumb" /></a></span
                      ><span class="ag_thumbclassic"
                        ><a
                          href="images/sampledata/galeria/2miejsce/1.jpg"
                          title="1.jpg"
                          class=""
                          rel="lightbox[AdmirorGallery01]"
                          target="_blank"
                          ><img
                            src="plugins/content/admirorgallery/admirorgallery/thumbs/galeria/2miejsce/1.jpg"
                            alt="1.jpg"
                            class="ag_imageThumb" /></a
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </span>
        </p>
        <p>&nbsp;</p>

       

        <span class="ag_hidden_ID" id="1"></span>
      </div>
    </article>
    <GalleryComponent/>
  </div>
</template>

<script>
import GalleryComponent from '../components/GalleryComponent.vue';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  components: {GalleryComponent}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

