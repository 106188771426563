<template>
  <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous"> -->

  <article class="item">
    <div class="content clearfix">
      <p dir="ltr" id="docs-internal-guid-c655ca01-7fff-3ee6-d8a0-13c6d9f96811" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              margin-left: 160px;
            ">
        <span style="
                font-size: 18pt;
                font-family: 'Comic Sans MS';
                color: #000000;
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CENNIK</span>
      </p>
      <br />
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
            ">
        <span style="
                font-size: 13.999999999999998pt;
                font-family: 'Comic Sans MS';
                color: #000000;
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">ZABIEGI PIELĘGNACYJNE:</span>
      </p><br>
      <ZabiegiPielegnacyjneTable :fields='fields' :ZabiegiPielegnacyjneDane="ZabiegiPielegnacyjneDane"></ZabiegiPielegnacyjneTable>

      
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
            ">
     
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
            "><br>
        <span style="
                font-size: 13.999999999999998pt;
                font-family: 'Comic Sans MS';
                color: #000000;
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">STYLIZACJA PAZNOKCI:</span>
      </p><br>
      <StylizacjaPaznokciTable :fields='fields' :StylizacjaPaznokciDane="StylizacjaPaznokciDane"></StylizacjaPaznokciTable>

    
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
      
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
      
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
    
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
     
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
       
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
     
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
       
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
    
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
      
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
        
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
   
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
    
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">

      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
 
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">

      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">
 
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">

      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
              margin-left: 40px;
            ">

      </p>
      <br />
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
            ">
        &nbsp;
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
            ">
        <span style="
                font-size: 13.999999999999998pt;
                font-family: 'Comic Sans MS';
                color: #000000;
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">HENNA:</span>
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              margin-left: 40px;
            ">
       
      </p><br>
      <HennaTable :fields='fields' :hennaDane="hennaDane"></HennaTable>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              margin-left: 40px;
            ">
       
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              margin-left: 40px;
            ">
       
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              margin-left: 40px;
            ">
       
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              margin-left: 40px;
            ">
       
      </p>
      <br />
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
            ">
        &nbsp;
      </p>
      <p dir="ltr" style="
              line-height: 1.2;
              margin-top: 0pt;
              margin-bottom: 0pt;
              text-align: center;
            ">
        <span style="
                font-size: 13.999999999999998pt;
                font-family: 'Comic Sans MS';
                color: #000000;
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              ">DEPILACJA:</span>
      </p>
      <br>
      
      
    </div>
    <Table :fields='fields' :depilacjaDane="depilacjaDane"></Table>
  </article>

</template>
<script>
import Table from '../components/Table.vue'
import HennaTable from '../components/HennaTable.vue';
import StylizacjaPaznokciTable from '../components/StylizacjaPaznokciTable.vue'
import ZabiegiPielegnacyjneTable from '../components/ZabiegiPielegnacyjne.vue'

export default {
  name: 'App',
  components: {
    Table,
    HennaTable,
    StylizacjaPaznokciTable,
    ZabiegiPielegnacyjneTable
  },

}
</script>