<template lang="">
    <div id="block-header">
        <div class="block-header wrapper">
          <header id="header" class="clearfix">
            <a id="logo" href="/">
              <p>
                <img alt="" src="images/logo.png" />
              </p>
            </a>

            <div id="menubar">
              <nav id="menu">
                <ul class="menu menu-dropdown">
                  <li class="level1 item103 active current">
                    <a href="/" class="level1 active current"
                      ><span>O salonie</span></a
                    >
                  </li>
                  <li class="level1 item104">
                    <a href="index.php/oferta-akt.html" class="level1"
                      ><span><router-link to="/oferta" style="color: #d52f9c;">Oferta</router-link></span></a
                    >
                  </li>
                  <li class="level1 item105">
                    <a href="index.php/galeria.html" class="level1"
                      ><span><router-link to="/galeria" style="color: #d52f9c;">Galeria</router-link></span></a
                    >
                  </li>
                  <li class="level1 item106">
                    <a href="index.php/kontakt.html" class="level1"
                      ><span><router-link to="/kontakt" style="color: #d52f9c;">Kontakt</router-link></span></a
                    >
                  </li>
                </ul>
              </nav>

              <div id="search">
                <form
                  id="searchbox-99"
                  class="searchbox"
                  action="http://salonurodyilona.pl/index.php"
                  method="post"
                  role="search"
                >
                  <input
                    type="text"
                    value=""
                    name="searchword"
                    placeholder="szukaj..."
                  />
                  <button type="reset" value="Reset"></button>
                  <input type="hidden" name="task" value="search" />
                  <input type="hidden" name="option" value="com_search" />
                  <input type="hidden" name="Itemid" value="103" />
                </form>

                
                
              </div>
            </div>
          </header>
        </div>
      </div>
</template>
<script>

export default {
    name: 'AppHeader'
}
// jQuery(function ($) {
//                     $("#searchbox-99 input[name=searchword]")
//                       .search({
//                         url: "/index.php/component/search/?tmpl=raw&amp;type=json&amp;ordering=&amp;searchphrase=all",
//                         param: "searchword",
//                         msgResultsHeader: "Wyniki wyszukiwania",
//                         msgMoreResults: "Więcej wyników",
//                         msgNoResults: "Brak wyników",
//                       })
//                       .placeholder();
//                   });
</script>
<style lang="">
    
</style>