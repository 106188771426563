<template>
    <article class="item">
        <div class="content clearfix">
          <h3>Adres naszego salonu:</h3>
          <table
            border="0"
            cellpadding="1"
            cellspacing="1"
            style="width: 100%"
          >
            <tbody>
              <tr>
                <td style="width: 50%">
                  <table
                    border="0"
                    cellpadding="1"
                    cellspacing="1"
                    style="width: 98%"
                  >
                    <tbody>
                      <tr>
                        <td
                          style="
                            border-bottom: 1px dotted black;
                          "
                        >
                          <div>
                            <img
                              alt=""
                              src="../../public/images/ikony/home.png"
                              style="padding: 5px"
                            />
                          </div>
                        </td>
                        <td
                          style="
                            border-bottom: 1px dotted black;
                          "
                        >
                          <div>
                            ul. Pogodna 50A lok 2<br />
                            20 - 354 Lublin
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            border-bottom: 1px dotted black;
                          "
                        >
                          <div>
                            <img
                              alt=""
                              src="../../public/images/ikony/phone.png"
                              style="padding: 5px"
                            />
                          </div>
                        </td>
                        <td
                          style="
                            border-bottom: 1px dotted black;
                          "
                        >
                          <div>tel. 667-266-851</div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="
                            border-bottom: 1px dotted black;
                          "
                        >
                          <div>
                            <img
                              alt=""
                              src="../../public/images/ikony/clock.png"
                              style="padding: 5px"
                            />
                          </div>
                        </td>
                        <td
                          style="
                            border-bottom: 1px dotted black;
                          "
                        >
                          <div>
                            <span
                              >poniedziałki i piątki od 11.00
                              do 18.00</span
                            >
                          </div>
                          <div>
                            <span
                              >wtorki, środy oraz czwartki od
                              9.00 do 16.00</span
                            >
                          </div>
                          <div>
                            <span
                              >soboty od 9.00 do 14.00 (lub na
                              zapisy)</span
                            >
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td>
                  <div style="border: 2px solid black">
                    <div class="moduletable">
                      <div
                        id="map-7-639713af94222"
                        class="wk-map wk-map-default"
                        style="height: 200px; width: 100%"
                        data-widgetkit="googlemaps"
                        data-options='{"title":"","lat":"51.2306509","lng":"22.583647","icon":"red-dot","popup":1,"text":"","mainIcon":"red-dot","style":"default","width":"auto","height":200,"mapTypeId":"roadmap","zoom":13,"mapCtrl":1,"typeCtrl":0,"directions":0,"unitSystem":0,"clusterMarker":0,"styler_invert_lightness":0,"styler_hue":"","styler_saturation":0,"styler_lightness":0,"styler_gamma":0,"adresses":[]}'
                      ><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1249.1508608665888!2d22.599962676317517!3d51.23193790496602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472256e33944b5a7%3A0x5ec8fb399b5c835c!2sPogodna%2050A%2C%2020-330%20Lublin!5e0!3m2!1spl!2spl!4v1672322014039!5m2!1spl!2spl" width="350" height="200" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
        </div>
      </article>
</template>