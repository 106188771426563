<template>

    <table   style ="width: 100%; border: solid white 1px;">
        <thead>
            <tr style="border: solid white 1px;">
                <!-- loop through each value of the fields to get the table header -->
                <!-- <th v-for="field in fields" :key='field' @click="sortTable(field)">
                    {{ field }} <i class="bi bi-sort-alpha-down" aria-label='Sort Icon'></i>
                </th> -->
            </tr>
        </thead>
        <tbody >
            <!-- Loop through the list get the each student data -->
            <tr class="tb" style="border: solid white 1px; " v-for="item in depilacjaDane" :key='item'>
                <!-- <td  v-for="field in fields" :key='field'>{{ item[field] }}</td> -->
                <td>{{ item['Nazwa'] }} <div style="float:right;">{{  item['Cena'] }} zł</div>  </td>
            </tr>
        </tbody>
    </table>
</template>
<style>
   
</style>
<script>
export default {
    name: 'TableComponent',
    setup() {
    //An array of values for the data
    const depilacjaDane = [
    {Nazwa: 'Wąsik / broda' , Cena:'20'},
    {Nazwa: 'Twarz', Cena: '50'},
    {Nazwa: 'Pachy' , Cena:'40'},
    {Nazwa: 'Nogi' , Cena:'110'},
    {Nazwa: 'Łydki', Cena: '55'},
    {Nazwa: 'Ręce', Cena:'60'},

      
// {Nazwa: 'Wąsik / broda' , Cena:'20'},
// {Nazwa: 'Twarz', Cena: '50'},
// {Nazwa: 'Pachy' , Cena:'40'},
// {Nazwa: 'Nogi' , Cena:'110'},
// {Nazwa: 'Łydki', Cena: '55'},
// {Nazwa: 'Ręce', Cena:'60'}
    ]
    const fields = [
      'Nazwa', 'Cena'
    ]
    return { depilacjaDane, fields }
  },
    
}
</script>+