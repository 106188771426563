<template lang="">
    <div id="ff_formdiv1" class="content_outline">
        <div class="bfPage-tl">
          <div class="bfPage-tr">
            <div class="bfPage-t"></div>
          </div>
        </div>
        <div class="bfPage-l">
          <div class="bfPage-r">
            <div class="bfPage-m bfClearfix">
              <form ref="form" @submit.prevent="sendEmail"
              id="ff_form1"
                
                class="bfQuickMode">
                <div id="ff_div1" style=" position: absolute; z-index: 0; left: 0px; top: 0px;">
                
              </div>
                <label>Imie i Nazwisko</label><br>
                <input type="text" name="user_name" style="width:100%">
                <br><label>Email</label><br>
                <input type="email" name="user_email" style="width:100%">
                <br><label>Wiadomość</label><br>
                <textarea name="message" style=width:300px;height:100px;></textarea><br>
                <input type="submit" value="Wyślij">
              </form>
            </div>
          </div>
        </div>
        <div class="bfPage-bl">
          <div class="bfPage-br">
            <div class="bfPage-b"></div>
          </div>
        </div>
      </div>
</template>
<script>
import emailjs from 'emailjs-com';

export default {
  methods: {
    sendEmail() {
      emailjs.sendForm('service_25i42a9', 'template_x06uv39', this.$refs.form, 'iI3hcD3Cf9bpv_cuF')
        .then((result) => {
          alert('Wiadomość wysłana!');
          console.log('SUCCESS!', result.text);
        }, (error) => {
          console.log('FAILED...', error.text);
        });
    }
  }
}
</script>
<style lang="">
    
</style>