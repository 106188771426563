
<template lang="">
    <div id="block-bottom-a" class="bg-white">
        <div class="block-bottom-a wrapper">
          <section id="bottom-a" class="grid-block">
            <div class="grid-box width33 grid-h">
              <div class="module mod1-box mod-box-default deepest">
                <h3 class="module-title">Formularz kontaktowy</h3>
                <!-- BreezingForms V1.8.1 Stable (build 807) Copyright(c) 2008-2009 by Markus Bopp | FacileForms Copyright 2004-2006 by Peter Koch, Chur, Switzerland.  All rights reserved. -->
                <div class="bfClearfix" style="width: 100%; text-align: center">
                  <div
                    class="bfClearfix"
                    style="
                      width: 305px;
                      height: 232px;
                      position: relative;
                      overflow: hidden;
                      text-align: left;
                      margin-left: auto;
                      margin-right: auto;
                    "
                  >
                  <ContactForm>asdf</ContactForm>
                    <!-- form end contact form -->
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-box width33 grid-h">
              <div class="module mod1-box mod-box-default deepest">
                <div id="kontakt">
                  <h3 class="module-title">Kontakt</h3>
                  <p>&nbsp;</p>
                  <h4>Salon Urody Ilona</h4>
                  <p>
                    Pogodna 50A lok 2<br />
                    20 - 354 Lublin
                  </p>
                </div>
              </div>
            </div>
            <div class="grid-box width33 grid-h">
              <div class="module mod1-box mod-box-default deepest">
                <div id="likebox_1" style="padding-left: 10px">
                  <div id="likebox_1_1" style="width: 290px; height: 200px">
                    <iframe
                      allowtransparency="true"
                      frameborder="0"
                      scrolling="no"
                      src="https://www.facebook.com/plugins/likebox.php?id=131403460340161&amp;locale=pl_PL&amp;width=293&amp;height=403&amp;colorscheme=light&amp;show_faces=true&amp;border_color&amp;stream=false&amp;header=false"
                      style="
                        border: none;
                        overflow: hidden;
                        width: 293px;
                        height: 258px;
                      "
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
</template>
<script>
import ContactForm from './ContactForm.vue';
export default {
    name: 'FooterComponent',
    components: {ContactForm}
}
</script>
<style lang="">

</style>