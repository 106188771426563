<template>

    <table  style ="width: 100%; border: solid white 1px;">
        <thead>
            <tr style="border: solid white 1px;">
                <!-- loop through each value of the fields to get the table header -->
                <!-- <th v-for="field in fields" :key='field' @click="sortTable(field)">
                    {{ field }} <i class="bi bi-sort-alpha-down" aria-label='Sort Icon'></i>
                </th> -->
            </tr>
        </thead>
        <tbody>
            <!-- Loop through the list get the each student data -->
            <tr class="tb" style="border: solid white 1px; " v-for="item in StylizacjaPaznokciDane" :key='item'>
                <!-- <td v-for="field in fields" :key='field'>{{ item[field] }}</td> -->
                <td>{{ item['Nazwa'] }} <div style="float:right;">{{  item['Cena'] }} zł</div>  </td>
            </tr>
        </tbody>
        
    </table>
</template>
<style>
  
    td {
        padding: 10px;
        text-align: center;
    }
</style>
<script>
export default {
    name: 'StylizacjaPaznokciTable',
    setup() {
    //An array of values for the data
    
    const StylizacjaPaznokciDane = [
    {Nazwa: 'Przedłużanie paznokci ( żel, akryl, akryżel) ', Cena:'od 150'}, 
    {Nazwa: 'Uzupełnienie ( żel, akryl, akryżel)  ', Cena:'od 100'},
      {  Nazwa: "Dorobienie paznokcia", Cena: 'od 10' },
      {Nazwa: 'Manicure z odżywka ', Cena:'50'},
      {Nazwa: 'Manicure z malowaniem ', Cena:'65'},
      {Nazwa: 'Japoński/ IBX ', Cena:'60'},
    //   {  Nazwa: "-manicure IBX", Cena: '60zł' },
    {Nazwa: 'Hybrydowy kolor ', Cena:'80'},
    {Nazwa: 'Hybrydowy zdobienia/ french/ ombre ', Cena: 'od 85'},
      {  Nazwa: "Dodatkowa warstwa baza/żel", Cena: '10' },
      {  Nazwa: "Usunięcie hybrydy z odżywką", Cena: '30 ' },
      {Nazwa: 'Usunięcie akrylu/ żelu ', Cena:'50'},
      {Nazwa: 'Pedicure podstawowy z odżywka', Cena: '85'},
      {Nazwa: 'Pedicure kolor ', Cena:'od 95'},
      {  Nazwa: "Pedicure leczniczy (modzele, nagniotki, odciski)", Cena: 'od 100' },
      {Nazwa: 'Korekta paznokcia', Cena: '25'},
      {Nazwa: 'Pedicure hybrydowy (kolor)' , Cena:'115'},
      {Nazwa: 'Pedicure hybrydowy french/ zdobienia ', Cena: 'od 125'},

    //   {Nazwa: 'Przedłużanie paznokci ( żel, akryl, akryżel) od ', Cena:'150'}, 
// {Nazwa: 'Uzupełnienie ( żel, akryl, akryżel)  ', Cena:'od 100'},
// {Nazwa: 'Manicure z odżywka ', Cena:'50'},
// {Nazwa: 'Manicure z malowaniem ', Cena:'65'},
// {Nazwa: 'Japoński/ ibx ', Cena:'60'},
// {Nazwa: 'Hybrydowy kolor ', Cena:'80'},
// {Nazwa: 'Hybrydowy zdobienia/ french/ ombre od', Cena: '85'},
// {Nazwa: 'Dodatkowa warstwa żelu/ bazy ', Cena:'10'},
// {Nazwa: 'Usunięcie hybrydy z odżywka ', Cena:'30'},
// {Nazwa: 'Usunięcie akrylu/ żelu ', Cena:'50'},
// {Nazwa: 'Pedicure podstawowy z odżywka', Cena: '85'},
// {Nazwa: 'Pedicure kolor od ', Cena:'95'},
// {Nazwa: 'Pedicure leczniczy od' , Cena:'100'},
// {Nazwa: 'Korekta paznokcia', Cena: '25'},
// {Nazwa: 'Pedicure hybrydowy kolor' , Cena:'115'},
// {Nazwa: 'Pedicure hybrydowy french/ zdobienia od', Cena: '125'},
    ]
    const fields = [
      'Nazwa', 'Cena'
    ]
    return { StylizacjaPaznokciDane,  fields }
  },
    
}
</script>+