<template>

    <table  style ="width: 100%; border: solid white 1px;">
        <thead>
            <tr style="border: solid white 1px;">
                <!-- loop through each value of the fields to get the table header -->
                <!-- <th v-for="field in fields" :key='field' @click="sortTable(field)">
                    {{ field }} <i class="bi bi-sort-alpha-down" aria-label='Sort Icon'></i>
                </th> -->
            </tr>
        </thead>
        <tbody>
            <!-- Loop through the list get the each student data -->
            <tr class="tb" style="border: solid white 1px; " v-for="item in hennaDane" :key='item'>
                <td>{{ item['Nazwa'] }} <div style="float:right;">{{  item['Cena'] }} zł</div>  </td>            </tr>
        </tbody>
        
    </table>
</template>
<style>
    
    td {
        padding: 10px;
        text-align: center;
    }
</style>
<script>
export default {
    name: 'HennaTable',
    setup() {
    //An array of values for the data
    
    const hennaDane = [
    {Nazwa: 'Henna brwi z regulacją ', Cena:'35'},
      {  Nazwa: "Henna rzęs", Cena: '20' },
      {  Nazwa: "Henna komplet", Cena: '45' },
      {  Nazwa: "Henna pudrowa z regulacją", Cena: '75' },
      {Nazwa: 'Regulacja brwi', Cena: '25'},
// {Nazwa: 'Komplet' , Cena:'45'},
// {Nazwa: 'Regulacja brwi', Cena: '25'},
    ]
    const fields = [
      'Nazwa', 'Cena'
    ]
    return { hennaDane,  fields }
  },
    
}
</script>+